import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PageHeader from '../components/PageHeader';
import Inner from '../components/Styles/Inner';
import Section from '../components/Section';
import SectionGradient from '../components/Section';
import Heading from '../components/Styles/Heading';
import Text from '../components/Styles/Text';
import Flex from '../components/Styles/Flex';
import Box from '../components/Styles/Box';
import Image from '../components/Image';
import Button from '../components/Buttons/Button';
import Card from '../components/Styles/Card';
import Faqs from '../components/Faqs';
import codeLinesWhite from '../images/code-lines-white.svg';

const BusinessesPage = ({ location, data }) => (
  <Layout location={location}>
    <SEO title="About Us" />
    <PageHeaderStyled
      heading="Solutions for <span class='highlight'>Business Owners</span>"
      subHeading="Founded in 2016 in Boston by Patrick Koulalis, ALTdev was formed to provide professional web-based services to businesses of all sizes, helping them reach their goals. We offer a wide range of design and development services while using the latest technologies. With over 15+ years of experience in web development, Our experts are ready for anything that comes our way."
      buttonText="Start a Project"
      buttonLink="/contact/"
      link="/services/"
      linkText="Checkout Our Services"
      arrow="true"
    />
    <SectionGradient>
      <Inner>
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1 / 3]} pr={[0, 0, 4]}>
            <Image image={data.rocketDeveloper.childImageSharp.fluid} />
          </Box>
          <Box width={[1, 1, 2 / 3]}>
            <Heading as="h2" color="white">
              Focus on scaling your business, not your WordPress website
            </Heading>
            <Text color="white">
              A website which is not fresh and regularly updated will look like
              a messy deserted house. You can’t attract users with such a
              website. Content revision, security, and user experience are some
              of the major areas that impact your overall website presence.
              Therefore, the company you deal with must stay up-to-date on
              latest technology, use the most productive method and offer
              excellent customer service. Web Design Retainer is built on such
              qualities.
            </Text>
          </Box>
        </Flex>
      </Inner>
    </SectionGradient>

    <Section>
      <Inner>
        <Heading as="h2">
          Are you spending more time on your website than you are your business?
        </Heading>
        <Heading as="h3">
          Are you spending more time on your website than you are your business?
        </Heading>
        <Flex>
          <Box width={[1]}>asd</Box>
        </Flex>
      </Inner>
    </Section>

    <Faqs
      faqs={[
        {
          question: 'Do I need a website for my small business?',
          answer:
            'The short answer is that there has never been a better or more important time to invest in a website for your business. A website offers a wide variety of benefits for small businesses, and most of these benefits increase in value exponentially year over year, just like the Internet itself.',
        },
        {
          question: 'How effective is a website for small businesses?',
          answer:
            'A website can help small businesses increase flexibility, control, branding and credibility. Eighty-four percent of U.S. consumers believe a business with a website is more credible than one that only has a social media page. To be credible and attract new customers, a website can make a difference.',
        },
        {
          question: 'Do I need a website for my small business?',
          answer:
            'The short answer is that there has never been a better or more important time to invest in a website for your business. A website offers a wide variety of benefits for small businesses, and most of these benefits increase in value exponentially year over year, just like the Internet itself.',
        },
        {
          question: 'How effective is a website for small businesses?',
          answer:
            'A website can help small businesses increase flexibility, control, branding and credibility. Eighty-four percent of U.S. consumers believe a business with a website is more credible than one that only has a social media page. To be credible and attract new customers, a website can make a difference.',
        },
      ]}
    />
  </Layout>
);

const PageHeaderStyled = styled(PageHeader)`
  min-height: 35vh;
  text-align: left;
  .inner {
    padding: 0 30% 0 0;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    right: -55%;
    opacity: 0.5;
    top: 0;
    z-index: -10;
    width: 100%;
    height: 95vh;
    background: url(${codeLinesWhite}) no-repeat;
  }
`;

BusinessesPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default BusinessesPage;

export const query = graphql`
  query {
    rocketDeveloper: file(relativePath: { eq: "rocket-developer.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    contentMarketing: file(
      relativePath: { eq: "content-marketing_202307290.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gatsbyIcon: file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
